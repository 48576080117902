
















































import { Component, Prop, Vue } from 'vue-property-decorator';
import MButton from '@/shared/components/MButton.vue';
import { TranslateResult } from 'vue-i18n';
import TrackingHelper from '@/shared/helpers/tracking';
import { AutomationActionType } from '@/types/automation';

type ActionOption = {
  name: TranslateResult;
  value: string;
};

@Component({
  components: {
    MButton,
  },
})
export default class AutomationsEditModalAddedByModel extends Vue {
  @Prop({ required: true }) modalId: string;
  @Prop({ required: true }) automationId: number;

  actions: ActionOption[] = [];
  selectedAction = {} as ActionOption;

  created() {
    this.actions = [
      { name: this.$t('automations.actions.Sendgrid.name'), value: AutomationActionType.SENDGRID },
      { name: this.$t('automations.actions.Webhook.name'), value: AutomationActionType.HERO_WEBHOOK },
    ];

    this.selectedAction = this.actions[0];
  }

  closeModal() {
    TrackingHelper.trackAutomationModalType(this.selectedAction.value, this.automationId);
    this.$emit('closeModal', this.selectedAction.value);
  }
}
